import "./App.scss";
import Home from "./sections/home/home";
import Kzen8ForBusinesses from "./sections/kzen8-for-businesses/kzen8-for-businesses";
import TopBar from "./components/top-bar/top-bar";
import WhatOthersSay from "./sections/what-others-say/what-others-say";
import Footer from "./sections/footer/footer";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./pages/about-us/about-us";
import Solutions from "./pages/solutions/solutions";
import Solution from "./pages/solutions/solution/solution";
import CustomerRelationships from "./pages/solutions/customer-relationships/customer-relationships";
import Careers from "./pages/careers/careers";
import NotFound from "./pages/not-found/not-found";
import ContactUs from "./pages/contact-us/contact-us";
import BackgroundWithShapes from "./components/background-with-shapes/background-with-shapes";
import WhatCanYouBuild from "./sections/what-can-you-build/what-can-you-build";
import EndlessPossibilities from "./sections/endless-possibilities/endless-possibilities";
import { useEffect } from "react";
import Features from "./sections/features/features";
import Kzen8ForIndustryExperts from "./sections/kzen8-for-industry-experts/kzen8-for-industry-experts";
import LoadingScreen from "./sections/loading-screen/loading-screen";
import { useState } from "react";
import UserPreferenceContext from "./contexts/user-preference-context";
import { visibilityCheck } from "./utils/visibility-check";
import { AiOutlineReload } from "react-icons/ai";
import Kzen8ForIndustryExpertsTwo from "./sections/kzen8-for-industry-experts-two/kzen8-for-industry-experts-two";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "@react-hook/window-size";
import { Button } from "@mui/material";
import { PlayCircleFilledTwoTone } from "@mui/icons-material";
import { FaClock } from "react-icons/fa";
import DomainFees from "./pages/domain-fees/domain-fees";
import PartnerLevels from "./pages/partner-levels/partner-levels";
import BecomeAKzenPartner from "./pages/become-a-kzen-partner/become-a-kzen-partner";
import Marquee from "react-fast-marquee";
import BusinessAssessment from "./pages/business-assessment/business-assessment";
import Platform from "./pages/platform/platform";

function App() {
  function setVw() {
    let vw = document.documentElement.clientWidth;
    document.documentElement.style.setProperty("--vw", `${vw}px`);
  }

  const [width, height] = useWindowSize();

  const [userPreference, setUserPreference] = useState('O');

  setVw();
  window.addEventListener("resize", setVw);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <UserPreferenceContext.Provider value={'O'}>
      <div className="App">
        {userPreference ? (
          <>
            <div className="spinner"></div>
            <div className="app-container">
              <TopBar setUserPreference={setUserPreference} />
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <>
                      {/* <img src="/images/wave-1.svg" className="backgroundColourWave1"></img> */}
                      <Home />
                      <div className='valuesSection full-width-element'>
                        <h1 className={`bigHeading`}> Our Values</h1>
                        <div className="testimonialContainer">
                          <Marquee gradientColor={[247, 247, 247]} speed={20} gradientWidth={width > 968 ? 200 : width > 720 ? 100 : 50}>
                            {[1, 2, 3, 4, 5, 6].map((num, idx) => (
                              <span key={idx} className="testimonial subtitle2">
                                <img src={`/images/values (${num}).png`} />
                              </span>
                            ))}
                          </Marquee>
                        </div>
                      </div>
                      <Solutions />


                    </>
                  }
                ></Route>
                <Route path="our-story" element={<AboutUs />}></Route>
                <Route path="contact-us" element={<ContactUs />}></Route>
                <Route exact path="solutions" element={<Solutions />}></Route>
                <Route exact path="business-assessment" element={<BusinessAssessment />}></Route>
                <Route exact path="pricing" element={<DomainFees />}></Route>
                <Route exact path="compare-partner-levels" element={<PartnerLevels />}></Route>
                <Route path="solutions/:solution" element={<Solution />}></Route>
                <Route path="platform" element={<Platform />}></Route>

                <Route path="careers" element={<Careers />}></Route>
                <Route path="partner" element={<BecomeAKzenPartner />}></Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
              <BackgroundWithShapes />
            </div>
            <div className="awsPartnerBadges">
              <h1 className="header">AWS Certified Solution Provider</h1>
              <div className="badgeContainer ">
                <img src="/images/badge.png" />
                <img src="/images/badge2.png" />
                <img src="/images/badge3.png" />
              </div>


            </div>
            <Footer />
          </>
        ) : (
          <LoadingScreen setUserPreference={setUserPreference} />
        )}
      </div>
    </UserPreferenceContext.Provider>
  );
}

export default App;
