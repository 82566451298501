import React from 'react'
import './background-with-shapes.scss';

function BackgroundWithShapes() {
  return (
    <div className='BackgroundWithShapes'>
        <img className='ellipse1' src='/images/Ellipse 1.svg' alt='ellipse 1'/>
        <img className='ellipse2' src='/images/Ellipse 2.svg' alt='ellipse 2'/>
        <img className='traingle1' src='/images/Traingle 1.svg' alt='Traingle 1'/>
    </div>
  )
}

export default BackgroundWithShapes