import React, { Component, useEffect, useState } from "react";
import "./become-a-kzen-partner.scss";

import { useWindowSize } from "@react-hook/window-size/throttled";
import LazyLoad from "react-lazyload";
import WhatOthersSay from "../../sections/what-others-say/what-others-say";
import Marquee from "react-fast-marquee";
import StrokePattern from "../../components/stroke-pattern/stroke-pattern";
import { Button } from "@mui/material";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { IoChatbox } from "react-icons/io5";
import { MdEditSquare } from "react-icons/md";

const BecomeAKzenPartner = () => {


  const [width, height] = useWindowSize();
  const [playerSize, setPlayerSize] = useState("768px");

  useEffect(() => {
    if (width > 1420) {
      setPlayerSize("512px");
    }
    else if (width > 720) {
      setPlayerSize("420px");
    } else {
      setPlayerSize("320px");
    }

  }, [width]);


  return (

    <div className="becomeAKzenPartner full-width-element" >
      {/* <img src="/images/wave-1.svg" className="backgroundColourWave1"></img> */}
      <StrokePattern styles={{ position: 'absolute', width: '125px', top: '120px', left: '-10px' }} />

      <div className="heroSection">
        <div className="leftSection animated slideUpAnimation initially-hidden body">



          <h1 className="bannerHeading tertiary-dark-text" id="becomeAKzenPartner">Generate Income
            <br /><span className="primary-text">With <span className="tertiary-text">Kzen8</span></span>
            <br />

            {/* <span className="primary-text">Kzen</span><span className="tertiary-text">8</span> */}

          </h1>


          <span className="body    delay-1-half">The Kzen8 Platform empowers our partners, or "Kzen8ors" as we like to call them, to enhance their offerings and create new revenue streams.

            <br /> <br /> Whether technical or commercial, Kzen8ors can generate additional income while fostering mutually beneficial opportunities for themselves, their clients, and Kzen8.<br></br><br></br></span>

          <div className="buttonContainer">
            <Link to="https://community.kzen8.com/" target='_blank'>
              <Button className="btn btn-tertiary callToAction subtitle2" variant="contained" endIcon={<MdEditSquare />}>
                Join Our Community
              </Button>
            </Link>
          </div>



        </div>
        <div className="rightSection">
          <StrokePattern styles={{ position: 'absolute', width: '125px', top: 0, right: '-100px' }} />

          <video src="/videos/8 Ways of Making Money with Kzen8 today!.mp4" playsInline="true" autoPlay="true" muted="false" controls alt="Kzen8 - Partner Expert">
            <track default kind="captions" srclang="en" src="/subtitles/captions from 8 Ways video.vtt" />
          </video>

          <StrokePattern styles={{ position: 'absolute', width: '225px', left: '-450px' }} />



        </div>
      </div>



      {/* <div className="testimonialContainer">
        <Marquee gradientColor={[247, 247, 247]} speed={20} gradientWidth={width > 968 ? 200 : width > 720 ? 100 : 50}>
          {[1, 2, 3, 1, 2, 3].map((num, idx) => (
            <span key={idx} className="testimonial subtitle2">
              <img src={`/images/testimonial 3 (${num}).png`} />
            </span>
          ))}
        </Marquee>
      </div> */}

      <div className="comparisonSection">
        <div className="leftSection">
          <div className="genericSection">
            <h1 className="header tertiary-dark-text accent">We Can Do It</h1>

            <span className="body">
              Kzen8 can do the configuration for you, turning your ideas into usable software
              solutions that look and work exactly the way
              you want.
            </span>


            <span className="body">
              You take responsiblity for selling the solution and receive up to 30% of ongoing license fees.
              <br />
                <br />
              Kzen8 looks after all the cloning and hosting for you.

            </span>
          </div>


          <div className="example body">

            <div className="description">

              <h2 className="headerSmall tertiary-dark-text">
                Murray Spiers
              </h2>
              <p>

                Murray Spiers, a
                Compliance Consultant,
                worked with Kzen8 to
                create software for the
                industry he knows best.
                <br />
                <br />

                Murray had the vision.
                Kzen8 brought it to life.
                <br />
                <br />

                Leveraging his established network, Murray now sells the
                platform and receives a percentage of all ongoing licence
                fees.

              </p>
            </div>

            <div className="imageContainer">

              <img src="/images/murray-no-bg.webp" className="heroImage">

              </img>

            </div>


          </div>
        </div>

        <div className="rightSection">
          <div className="genericSection">

            <h1 className="header tertiary-dark-text  accent">You Can Do It</h1>
            <span className="body">
              Sign up and create as many business software solutions as you can think of
              without having to write a single line of code.

              <br />
              <br />
              Create it. Clone it. Sell it at a monthly recurring price you choose. 
              <br />
              <br />
              All you pay Kzen8 are monthly hosting fees at our standard rates.

            </span>

            <br />

          </div>

          <p className="example body">

            <span>
              Build from the ground up or start with one of our blueprints.

              <br />
              <br />

              Configure the data tables, page flow, page layouts,
              functionality, process flow… all the way to
              button colour and font size.

              <br />
              <br />
              Clone and resell your solutions as many times as you want.
              <br />
              <br />
              You can even roll out any updates you make to your solution to all customers with a single click.
              <br />
              <br />
            </span>

          </p>


        </div>
      </div>

    </div>
  );
}

export default BecomeAKzenPartner;
