import { useWindowSize } from "@react-hook/window-size/throttled";
import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import FilledPattern from "../../components/filled-pattern/filled-pattern";
import "./endless-possibilities.scss";


import { FaArrowRight, FaMapMarkerAlt } from "react-icons/fa";
import { FcComboChart, FcDocument, FcGraduationCap, FcPhotoReel, FcViewDetails } from "react-icons/fc";
import { Link } from "react-router-dom";

const EndlessPossibilities = () => {
  const widgets = [
    {
      title: 'List',
      imageLink: "/images/list-widget.png",
      icon: <FcViewDetails />,
      helperImages: [
        {
          image: "/images/list-helper-image.webp",
          styles: {
            right: "20%",
            bottom: "0",
            position: "absolute",
          },
        },
      ],
    },
    {
      title: 'Form',
      imageLink: "/images/form-widget.png",
      icon: <FcDocument />,
      helperImages: [
        {
          image: "/images/test-helper-image.webp",
          styles: {
            right: "20%",
            bottom: "0",
            position: "absolute",
          },
        },
      ],
    },
    {
      title: 'Chart',
      imageLink: "/images/chart-widget.png",
      icon: <FcComboChart />,
      helperImages: [
        {
          image: "/images/chart-helper-image.webp",
          styles: {
            right: "30%",
            bottom: "0",
            position: "absolute"
          },
        },
      ],
    },
    {
      title: 'Map',
      imageLink: "/images/map-widget.png",
      icon: <FaMapMarkerAlt color="#FF4000" />,
      helperImages: [
        {
          image: "/images/map-helper-image.webp",
          styles: {
            right: "20%",
            bottom: "0",
            position: "absolute",
          },
        },
      ],
    },
    {
      title: 'Video',
      imageLink: "/images/video-widget.png",
      icon: <FcPhotoReel />,
      helperImages: [
        {
          image: "/images/video-helper-image.webp",
          styles: {
            right: "20%",
            bottom: "0",
            position: "absolute",
            width: "350px",
          },
        },
      ],
    }
  ];

  const [active, setActive] = useState(1);

  return (
    <div className="sectionContainer EndlessPossibilitiesContainer full-width-element">
      <h1 className="bigHeading">Endless possibilities</h1>

      <div className="EndlessPossibilities">
        {/* <StrokePattern styles={{ width: "300px", height: "300px", position: "absolute", left: "-100px" }} /> */}

        <section className="left">
          <h2 className="header">Complete development platform</h2>

          <div>
            <p className="body">
              Build custom systems without writing any code, combining the expanding toolkit of <span className="primary-text">widgets</span> available in the Kzen8 platform {" "}
            </p>
            <p className="body">Some of the available widgets include:</p>
          </div>

          <div className="widget-list">
            {widgets.map((widget, idx) => (
              <span
                key={idx}
                className="body app-background-text-colour widget"
                data-active={active === idx + 1}
                onClick={() => {
                  setActive(idx + 1);
                }}
              >
                {widget.title}
                {widget.icon}
              </span>
            ))}
          </div>
          <span className="subtitle2">and many more.</span>
          <Link to="/solutions">
            <span className="subtitle1 accent learnMore">
              Learn more <FaArrowRight />{" "}
            </span>
          </Link>

        </section>
        <section className="right">
          <img src={widgets[active - 1].imageLink} alt={widgets[active - 1].imageLink}/>
          {widgets[active - 1].helperImages?.map((image, idx) => (
            <img src={image.image} style={image.styles} alt={image.image} key={idx} className="helperImage" />
          ))}
        </section>
      </div>
    </div>
  );
};

export default EndlessPossibilities;
