import { useWindowSize } from '@react-hook/window-size/throttled';
import React, { useEffect, useState } from 'react';
import './not-found.scss';

const NotFound = () => {

    const [width, height] = useWindowSize();
    const [playerSize, setPlayerSize] = useState("768px");


    useEffect(() => {
        if (width > 720) {
            setPlayerSize("768px");
        } else {
            setPlayerSize("320px");
        }
        
    }, [width]);


    return (
        <div className="notFound">
            <lottie-player
                src="/lottie-files/lf20_ncowbyfu.json"
                background="transparent"
                speed="1"
                style={{ width: playerSize, height: playerSize }}
                autoplay
                loop
            ></lottie-player>
        </div>
    );
}

export default NotFound;
