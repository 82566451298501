import React from 'react';
import { Button } from "@mui/material";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { RiUserAddFill } from "react-icons/ri";
import { Link } from 'react-router-dom';

const HomeCommercial = () => {
    return (
        <div>

            <div className="middleSection">
                <div className="leftSection initially-hidden animated slideUpAnimation">
                    <h1 className="bannerHeading primary-text-colour">
                    Generate <span className="primary-text">Income</span> <br/>From Expertise
                    </h1>

                    <p className="body"> Kzen8 is revolutionising the business software industry with our game changing no code platform.
                    </p>
                    {/* <p className="body">
            Have you engaged coaches and consultants to help scale your business but found the results to be disappointing?
            </p> */}
                    <p className="body">Find out how the Kzen8 platform empowers anyone to generate additional income for themselves, even if they have no technical skills.
                    </p>
                    <div className="buttonContainer">
                    <Link to="/contact-us">
                            <Button className="btn btn-primary callToAction subtitle2" variant="contained" endIcon={<BsFillArrowRightCircleFill />}>
                                Talk to a consultant
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="rightSection initially-hidden animated slideLeftAnimation">
                    {/* <lottie-player
              src="/lottie-files/home-animation.json"
              // src="https://assets7.lottiefiles.com/packages/lf20_tcwozhzv/MarketingCampaignsViralMethods.json"
              background="transparent"
              speed="1"
              style={{ width: playerSize, height: playerSize }}
              autoplay
              loop
            ></lottie-player> */}
                </div>
                <div className="heroImageContainer">
                    <img src="/images/hero-image-commercial.webp" alt="hero-section-image"></img>
                    <img src="/images/Popup1-commercial.svg" alt="popup1" key="popup1-commercial" className="popup1 scaleIn" />
                    <img src="/images/Popup2-commercial.svg" alt="popup1" key="popup2-commercial" className="popup2  scaleIn" />
                    <img src="/images/Popup3-commercial.svg" alt="popup1" key="popup3-commercial" className="popup3  scaleIn" />


                </div>
            </div>
        </div>
    );
}

export default HomeCommercial;
