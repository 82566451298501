import { useWindowSize } from '@react-hook/window-size/throttled';
import React, { useEffect, useRef, useState } from 'react';
import './about-us.scss';

import StrokePattern from '../../components/stroke-pattern/stroke-pattern';
import Marquee from 'react-fast-marquee';

const AboutUs = () => {

    const [width, height] = useWindowSize();


    const team = [
        {
            title: 'Huw Pryse Lloyd (Founder and CEO)',
            profilePictureSrc: '/images/huw2.webp',
            bio: [
                `Huw has had a passion for developing business software
            since the age of 8 and now has over 45 years of software
            development experience. After achieving top of his class in
            Business Studies at Edinburgh University (Scotland), Huw
            qualified in 1996 as a Chartered Accountant in London (UK).`,

                `Since 1996 Huw worked as both an IT contractor and for 9 years
            was a Director of a software business. After emigrating to
            Australia with his wife Helen and 4 children in 2013, Huw
            became an Australian citizen in 2020.`,

                `In 2015 Helen and Huw started a Home Care business delivering care to both elderly and
clients with disabilities. Huw’s responsibility was to develop the business processes and
manage the business finances, which resulted in Huw developing a care management
system that became the core operational system of the business managing all the
scheduling, staff records, client records and finances for the 175 staff and over 400 clients.
The business grew 100% year on year for 5 straight years and due to the operational
efficiency achieved as direct result of the systems and processes in place both Helen and
Huw worked no more than 5 hours per week in the business by the time they successfully
sold the business in January 2023. The essence of the system that was developed for the
Home Care business helped shape the fundamental requirements of The Kzen8 Platform.`
            ]
        },
        {
            title: 'Helen Pryse Lloyd (CXO - Chief eXperience Officer)',
            profilePictureSrc: '/images/helen.webp',
            bio: [
                `Helen has a passion for building thriving businesses from the
                ground up. Having successfully founded, scaled, and sold a Home
                Care business, Helen intimately understands the intricate dynamics
                of company culture and its profound impact on team productivity
                and motivation.`,

                `Helen has over 25 years of experience leading and training staff
                and teams. Between 2003 and 2012 Helen held various corporate
                roles where she was responsible for management training
                programs and developing leadership skills in the care industry.`,

                `In 2015 Helen founded Living Made Easy, a Home Care Company that employed over 175
                staff and operated across NSW and QLD. Through this venture, she gained invaluable
                insights into the pivotal role of company culture in driving business success.`,

                `Helen's is dedicated to cultivating company culture. She is passionate about streamlining HR
processes and has developed an internal HR Kzen8 solution to ensure compliance and
process ease.`
            ]
        },
        {
            title: 'Julius Haralampou (Head of Sales)',
            profilePictureSrc: '/images/Julius.webp',
            bio: [
                `Julius is a Tech Enthusiast-cum-Sales professional with a
                passion for emerging business technologies. With over 15 years
                experience in technology sales and strategy, including ERP,
                Ecommerce and Software, Julius thoroughly understands the short
                and long-term goals of both SME and large multi-national
                organisations.`,

                `In the current landscape of rapidly evolving business technology,
                Julius avidly keeps him and his clients updated with all emerging
                trends, technologies and possibilities. With his knowledge of
                corporate video production, Julius communicates complex business concepts in a simple
                manner in order to educate prospective clients on the benefits of Kzen8.`,

                `As the Head of Sales of Kzen8, Julius passionately broadcasts awareness about the true
                power of the no-code platform. Concurrently driving multiple sales conversations to
                mutually beneficial solutions is the heart beat of Julius’ daily activities.`
            ]
        },
        {
            title: 'Matt Judge (Head of Operations)',
            profilePictureSrc: '/images/MattJ.webp',
            bio: [
                `Matt was practically born into the world of technology. With
                both parents employed by IBM and his early years spent
                assembling computers, his passion for the field has been ingrained
                since childhood.`,

                `In recent years, Matt has honed his expertise in global business
                development, focusing specifically on building teams and software
                implementation projects within Retail, Hospitality, and Healthcare
                sectors for the past seven years.`,

                `With his experience as Head of Operations at Kzen8, Matt is well-positioned to guide the
                company through its growth phase and expanded operations as he steps into the role of
                COO soon.`
            ]
        },
        {
            title: 'Sankit Shrestha (Senior Software Engineer - Core Engine / UI/UX / Security)',
            profilePictureSrc: '/images/Sankit-nobg.webp',
            bio: [
                `Skilled creative/software developer, Sankit leverages a
                powerful set of technology and design skills to strategise and
                develop interactive web applications of all scales.`,

                `Sankit's ever-so-curious mind has enabled him to develop a plethora of skills ranging from back-end to front-end
                technologies, such as React, Angular, Typescript, Node.js, Mongodb and Python etc and broaden his skillset everyday. 
                As a result, he has been incharge of developing multiple core features in our platfrom such as database I/O, data visualisation, user authentication, and many more.`,

                `One of Sankit's most impressive accomplishments is the design and
                development of our entire website. What's even more
                remarkable is that he took the initiative to do this on his own.
                Sankit's dedication and self-motivation are truly outstanding.`,

                `In addition to the website, Sankit has been instrumental in
building the entire customer-facing side of our platform. He took
charge of designing the core application, styling, and user interface, excelling in every aspect
of UI and UX design. His ability to seamlessly blend creativity with technical prowess is truly
remarkable.`
            ]
        },
        {
            title: 'Matt Ellis (Senior Software Engineer - UX / Functionality / Testing)',
            profilePictureSrc: '/images/MattE2.webp',
            bio: [
                `Matt is a passionate and creative software engineer
                experienced in a range of both back-end and front-end
                technologies, such as Typescript, Node.js, Go and Python.`,

                `Matt enjoys solving complex and unique problems, whether it be
                system design, writing clean and efficient code or creating
                attractive and easy to use user interfaces.`,

                `Matt is a lifelong learner who is always looking to expand his
                knowledge and skills with a particular interest in developing
                interpreters / compilers, Emulation and Machine learning. In his spare time Matt enjoys
                playing tennis, chess, reading and video games.`
            ]
        },
        {
            title: 'Alex Walker (Senior Software Engineer - Backend Processing / Dev Ops)',
            profilePictureSrc: '/images/Alex.webp',
            bio: [
                `Alex is a software engineer, focusing on data processing,
                cloud architecture, system design and DevOps. Graduating with a
                degree in biology in 2016, Alex pivoted towards his passion of
                software development and quickly became interested in back-
                end web development and computer science. 7 years later, Alex
                uses various programing languages on a daily basis including
                Javascript/Typescript, Rust, C#, and Python.`,

                `Alex primarily works on back-end and data processing tasks,
                monitoring, API integrations and maintaining our AWS cloud set
                up.`,

                `Alex has also developed and maintained a number of internal utilities that are used for day-
                to-day Kzen8 development.`
            ]
        }
    ]

    return (
        <div className="aboutUs full-width-element">

            <h1 className={`bannerHeading`}> Our Story</h1>

            <p>
                <img src="/images/huw2.webp" />
                <span className="body    delay-1-half">Hi, I am Huw Pryse Lloyd, the founder of Kzen8. <br></br><br></br>
                    Back in 2015 my wife Helen and I started a home care business in Australia after securing a $50k loan from the bank. Originally operating out of our garage, we built up the business from scratch to over 400 clients and 175 staff, doubling revenues every year for 5 consecutive years. We successfully sold the business in January 2023.
                    <br></br><br></br>Whilst Helen was the CEO and managed all the care aspects of the business, Huw developed the entire back office and finance systems for the business from scratch and by the time the business was sold, the systems were so effective and efficient that neither Helen nor Huw spent more than 5 hours per week working in the business, but instead focused on the business -  The business operations simply ran like clockwork.
                    <br></br><br></br>Everything we learned from the home care business inspired us to create Kzen8, so that other businesses could also have the systems and processes they needed to make their business operations run like clockwork too.
                    <br></br><br></br>The ultimate aim was to develop a business software platform that was so easy to use that non-developer professionals could build business software without needing to know how to write code. The Kzen8 Platform has surpassed even our expectations and we are so proud of the amazing team that has been with us since day 1 and what we have built together, and now we are excited about showing you how to use the platform to realise your dreams too.
                    <br></br><br></br>We hope you enjoy using Kzen8 as much as we enjoy continuously striving to make it better and better.


                </span>

            </p>

            <div className='valuesSection'>
                <h1 className={`bigHeading`}> Our Values</h1>
                <div className="testimonialContainer">
                    <Marquee gradientColor={[247, 247, 247]} speed={20} gradientWidth={width > 968 ? 200 : width > 720 ? 100 : 50}>
                        {[1, 2, 3, 4, 5, 6].map((num, idx) => (
                            <span key={idx} className="testimonial subtitle2">
                                <img src={`/images/values (${num}).png`} />
                            </span>
                        ))}
                    </Marquee>
                </div>
            </div>


            <div className='meetTheTeamSection'>
                <h1 className={`bannerHeading`}> Meet The Team</h1>
                <div className="teamContainer">
                    {team.map((member, idx) => (
                        <span key={idx} className="member subtitle2">
                            <div className='profilePicture' style={{ backgroundImage: `url(${member.profilePictureSrc})` }} />
                            <h2 className='headerSmall'>{member.title}</h2>
                            <p className='body'>
                                {
                                    member.bio.map((para, idx) => <span key={idx}>{para}<br /><br /></span>)
                                }
                            </p>
                        </span>
                    ))}
                </div>
            </div>


            <StrokePattern styles={{ position: 'absolute', width: '125px', bottom: 0, left: '100px' }} />

            <StrokePattern styles={{ position: 'absolute', width: '225px', top: '100px', right: '150px' }} />

        </div>
    );
}

export default AboutUs;
