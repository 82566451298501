import React, { useState } from 'react';
import './loading-screen.scss';
import StrokePattern from '../../components/stroke-pattern/stroke-pattern';
import FilledPattern from '../../components/filled-pattern/filled-pattern';
import { useNavigate } from 'react-router-dom';

const LoadingScreen = ({ setUserPreference }) => {
    const navigate = useNavigate();

    const options = [
        {
            text: 'Transforming Organisations',
            imgUrl: '/images/improve.png',
            code: 'O',
            color: '#E34623',
            width: 75,
            height: 75,
            lottieSrc: "/lottie-files/organisation.json",
            overLayText: "Find out how the Kzen8 platform empowers organisations of any size operate the way they want to, making them more efficient, scalable, and profitable."
        },
        {
            text: 'Generating Income',
            imgUrl: "/images/income.png",
            code: 'C',
            color: '#3495D6',
            width: 125,
            height: 125,
            lottieSrc: "/lottie-files/income.json",
            overLayText: "Find out how the Kzen8 platform empowers anyone to generate additional income for themselves, even if they have no technical skills."
        },
        {
            text: 'Building Software',
            imgUrl: '/images/build.png',
            code: 'T',
            color: '#FE8501',
            width: 150,
            height: 150,
            speed: 0.25,
            lottieSrc: "/lottie-files/cube.json",
            overLayText: "Find out how the Kzen8 platform empowers anyone to build world-class business software solutions, even if they are not a software engineer."

        }
    ];

    const [imgUrl, setImgUrl] = useState("/images/Loading.png");


    return (
        <div className='loadingScreen'>
            <img className="logo" src="/images/logo-new.png" alt="logo" />
            <h3 className="headerSmall">Empowering anyone to build world-class flexible business software without writing any code.
            </h3>
            <img src="/images/Dotted-random-pattern.svg" className="dotted-random-pattern" />

            <span className="subtitle2">What are you interested in today?</span>
            <div className="optionContainer" onMouseLeave={() => setImgUrl("/images/Loading.png")}>
                {options.map(option => {
                    return <div className='option body' onClick={() => 
                    {
                        navigate('/')
                        setUserPreference(option.code)
                    }}>

                        <lottie-player
                            src={option.lottieSrc}
                            background="transparent"
                            speed={option.speed ?? 1}
                            style={{ width: option.width ?? 150, height: option.height ?? 150 }}
                            autoplay
                            loop
                        ></lottie-player>
                        {option.text}

                        <div className='overlay'>
                            <span>{option.overLayText}</span>
                        </div>


                    </div>
                })}
            </div>

            <div className="background">
            {/* <img src="/images/Dotted-random-pattern.svg" className="dotted-random-pattern" /> */}

                {/* <img src={imgUrl} alt="" /> */}
                {/* <FilledPattern styles={{ zIndex: 1, width: '25vw', maxWidth: 'unset' }} /> */}

            </div>


        </div>
    );
};



export default LoadingScreen;
