import React, { useState } from "react";
import "./partner-levels.scss";
import { FaCircleXmark } from "react-icons/fa6";
import { BsFillArrowRightCircleFill, BsFillCheckCircleFill, BsQuestionCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const PartnerLevels = () => {
  const partnerLevelsSource = [
    {
      level: "Kzen8or",
      features: [
        {
          featureName: "Monthly subscription $ AUD",
          value: "FREE",
        },
        {
          featureName: "Technical level access (max)",
          value: 4,
        },
        {
          featureName: "Apply themes from theme library",
          value: true,
        },
        {
          featureName: "KzenRings earned",
          value: "1-3 depending on early adopter status",
        },
        {
          featureName: "KzenRing discount per month off domain / KzenEnterprise fees",
          value: "$AUD 100",
        },
        {
          featureName: "KzenRing discount applies to",
          value: "Single nominated domain",
        },
        {
          featureName: "KzenRings earned",
          value: "1 per 5,000 KzenCoin",
        },
        {
          featureName: "KzenCoin earned from new referrals when they become a partner",
          value: "500",
        },
      ],
    },
    {
      level: "KzenPartner",
      features: [
        {
          featureName: "Monthly subscription $ AUD",
          value: "$200",
        },
        {
          featureName: "Technical level access (max)",
          value: 6,
        },
        {
          featureName: "Dedicated cluster options",
          value: "Single domain per cluster",
        },
        {
          featureName: "Apply themes from theme library",
          value: true,
        },
        {
          featureName: "Access to theme designer",
          value: true,
        },
        {
          featureName: "Personal copy of KzenHero Consulting Platform",
          value: true,
        },
        {
          featureName: "Collaboration (profile, find a seller / builder)",
          value: true,
        },
        {
          featureName: "Profile badge",
          value: "KzenPartner",
        },
        {
          featureName: "KzenRings earned",
          value: "1",
        },
        {
          featureName: "KzenRing discount per month off domain / KzenEnterprise fees",
          value: "$AUD 100",
        },
        {
          featureName: "KzenRing discount applies to",
          value: "Single nominated domain",
        },
        {
          featureName: "KzenRings earned",
          value: "1 per 5,000 KzenCoin",
        },
        {
          featureName: "KzenCoin earned from new referrals when they become a partner",
          value: "500",
        },
        {
          featureName: "KzenCoin earned from approved themes",
          value: "250",
        },
        {
          featureName: "KzenCoin earned from support forum participation",
          value: "10,000 split over top 10 contributors for the month PLUS 20,000 split over top 20 contributors for the quarter",
        },
      ],
    },
    {
      level: "Gold KzenPartner",
      features: [
        {
          featureName: "Monthly subscription $ AUD",
          value: "$500",
        },
        {
          featureName: "Technical level access (max)",
          value: 8,
        },
        {
          featureName: "Domain fee payment via consolidated invoice",
          value: true,
        },
        {
          featureName: "Dedicated cluster options",
          value: "Multiple domains per cluster",
        },
        {
          featureName: "Advanced KzenHub options",
          value: `App cleanser \n
          Clone protection \n
          Backup to and restore from file \n
          Multi application merge \n
          Date archiving \n`,
        },
        {
          featureName: "KzenEnterprise versions available",
          value: "Kzen8 Branded Only",
        },
        {
          featureName: "Apply themes from theme library",
          value: true,
        },
        {
          featureName: "Access to theme designer",
          value: true,
        },
        {
          featureName: "Personal copy of KzenHero Consulting Platform",
          value: true,
        },
        {
          featureName: "Collaboration (profile, find a seller / builder)",
          value: true,
        },
        {
          featureName: "Profile badge",
          value: "Gold KzenPartner",
        },
        {
          featureName: "KzenRings earned",
          value: "3",
        },
        {
          featureName: "KzenRing discount per month off domain / KzenEnterprise fees",
          value: "$AUD 100",
        },
        {
          featureName: "KzenRing discount applies to",
          value: "Consolidated invoice total",
        },
        {
          featureName: "KzenRings earned",
          value: "1 per 5,000 KzenCoin",
        },
        {
          featureName: "KzenCoin earned from new referrals when they become a partner",
          value: "500",
        },
        {
          featureName: "KzenCoin earned from approved themes",
          value: "250",
        },
        {
          featureName: "KzenCoin earned from support forum participation",
          value: "10,000 split over top 10 contributors for the month PLUS 20,000 split over top 20 contributors for the quarter",
        },
        {
          featureName: "KzenCoin earned from approved informative videos",
          value: "2,000",
        },
      ],
    },
    {
      level: "Platinum KzenPartner",
      features: [
        {
          featureName: "Monthly subscription $ AUD",
          value: "$1,000",
        },
        {
          featureName: "Technical level access (max)",
          value: "ALL",
        },
        {
          featureName: "Domain fee payment via consolidated invoice",
          value: true,
        },
        {
          featureName: "Dedicated cluster options",
          value: "Multiple domains per cluster",
        },
        {
          featureName: "Advanced KzenHub options",
          value: `App cleanser \n
          Clone protection \n
          Backup to and restore from file \n
          Multi application merge \n
          Date archiving \n`,
        },
        {
          featureName: "KzenEnterprise versions available",
          value: "Kzen8 Branded & White Labelled",
        },
        {
          featureName: "Apply themes from theme library",
          value: true,
        },
        {
          featureName: "Access to theme designer",
          value: true,
        },
        {
          featureName: "Personal copy of KzenHero Consulting Platform",
          value: true,
        },
        {
          featureName: "Collaboration (profile, find a seller / builder)",
          value: true,
        },
        {
          featureName: "Profile badge",
          value: "Platinum KzenPartner",
        },
        {
          featureName: "Advertise products/services on KzenMarket",
          value: true,
        },
        {
          featureName: "KzenRings earned",
          value: "6",
        },
        {
          featureName: "KzenRing discount per month off domain / KzenEnterprise fees",
          value: "$AUD 100",
        },
        {
          featureName: "KzenRing discount applies to",
          value: "Consolidated invoice total",
        },
        {
          featureName: "KzenRings earned",
          value: "1 per 5,000 KzenCoin",
        },
        {
          featureName: "KzenCoin earned from new referrals when they become a partner",
          value: "500",
        },
        {
          featureName: "KzenCoin earned from approved themes",
          value: "250",
        },
        {
          featureName: "KzenCoin earned from support forum participation",
          value: "10,000 split over top 10 contributors for the month PLUS 20,000 split over top 20 contributors for the quarter",
        },
        {
          featureName: "KzenCoin earned from approved informative videos",
          value: "2,000",
        }
      ],
    },
  ];

  const featuresSource = [
    {
      groupName: "Kzen8 Platform Features",
      features: [
        {
          title: "Monthly subscription $ AUD",
          tooltip: ""
        },
        {
          title: "Technical level access (max)",
          tooltip: ""
        },
        {
          title: "Domain fee payment via consolidated invoice",
          tooltip: ""
        },
        {
          title: "Advanced KzenHub options",
          tooltip: ""
        },
        {
          title: "KzenEnterprise versions available",
          tooltip: ""
        },
        {
          title: "Apply themes from theme library",
          tooltip: ""
        },
        {
          title: "Access to theme designer",
          tooltip: ""
        },
        {
          title: "Personal copy of KzenHero Consulting Platform",
          tooltip: ""
        }
      ],
    },
    {
      groupName: "KzenWeb Features",
      features: [
        {
          title: "Collaboration (profile, find a seller / builder)",
          tooltip: ""
        },
        {
          title: "Profile badge",
          tooltip: ""
        },
        {
          title: "Advertise products/services on KzenMarket",
          tooltip: ""
        }
      ],
    },
    {
      groupName: "KzenCoin / KzenRing Features",
      features: [
        {
          title: "KzenRings earned",
          tooltip: ""
        },
        {
          title: "KzenRing discount per month off domain / KzenEnterprise fees",
          tooltip: ""
        },
        {
          title: "KzenRing discount applies to",
          tooltip: ""
        },
        {
          title: "KzenRings earned",
          tooltip: ""
        },
        {
          title: "KzenCoin earned from new referrals when they become a partner",
          tooltip: ""
        },
        {
          title: "KzenCoin earned from approved themes",
          tooltip: ""
        },
        {
          title: "KzenCoin earned from support forum participation",
          tooltip: ""
        },
        {
          title: "KzenCoin earned from approved informative videos",
          tooltip: ""
        }
      ],
    },
  ];

  return (
    <div className="partnerLevels sectionContainer full-width-element">
      <h1 className="bigHeading primaryText">Partner Levels</h1>
      <h2 className="subtitle1 primaryText">Simple, transparent pricing</h2>

      {/* <Link to="/contact-us">
        <Button className="btn btn-secondary callToAction subtitle2" variant="outlined" endIcon={<BsFillArrowRightCircleFill />}>
          Talk to a consultant
        </Button>
      </Link> */}
      <div className="container">
        <div className="boxes">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="headerSection">
          <h3>Partner Level</h3>
          {partnerLevelsSource.map((partnerLevel) => {
            return <h3>{partnerLevel.level}</h3>;
          })}
        </div>

        <div className="bodySection body">
          {featuresSource.map((featureGroup) => (
            <div className="featureGroup">
              <h3 className="body featureGroupName" style={{ textAlign: "left" }}>{featureGroup.groupName}</h3>
              <div className="featureContainer">
                {featureGroup.features.map((feature, idx) => (
                  <div className={`feature ${idx % 2 === 0 ? "" : "alternate"}`}>
                    <span className="body featureTitle">{feature.title}
                      {feature.tooltip ? <span class="tooltip"><BsQuestionCircleFill />
                        <span class="tooltiptext">{feature.tooltip}</span>
                      </span> : ''}
                    </span>
                    {partnerLevelsSource.map((level) => {
                      const exists = level.features.find((innerFeature) => innerFeature.featureName === feature.title);
                      if (exists) {
                        if (exists.value === true) {
                          return (
                            <span className="tick">
                              <BsFillCheckCircleFill />
                            </span>
                          );
                        }
                        return <span className="body">{exists.value}</span>;
                      } else {
                        return (
                          <span className="times">
                            <FaCircleXmark />
                          </span>
                        );
                      }
                    })}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="containerSmall">
        {partnerLevelsSource.map((level) => {
          return (
            <>
              <div className="headerSection">
                <h3>Partner Level</h3>
                <h3>{level.level}</h3>
              </div>
              <div className="bodySectionSmall body">
                {featuresSource.map((featureGroup) => (
                  <div className="featureGroup">
                    <h3>{featureGroup.groupName}</h3>
                    <div className="featureContainer">
                      {featureGroup.features.map((feature, idx) => {
                        const exists = level.features.find((innerFeature) => innerFeature.featureName === feature.title);
                        if (exists) {
                          if (exists.value === true) {
                            return (
                              <div className={`feature ${idx % 2 === 0 ? "" : "alternate"}`}>
                                <span className="body featureTitle">{feature.title}
                                  {feature.tooltip ? <span class="tooltip"><BsQuestionCircleFill />
                                    <span class="tooltiptext">{feature.tooltip}</span>
                                  </span> : ''}
                                </span>
                                <span className="tick">
                                  <BsFillCheckCircleFill />
                                </span>
                              </div>
                            );
                          } else {
                            return (
                              <div className={`feature ${idx % 2 === 0 ? "" : "alternate"}`}>
                                <span className="body featureTitle">{feature.title}
                                  {feature.tooltip ? <span class="tooltip"><BsQuestionCircleFill />
                                    <span class="tooltiptext">{feature.tooltip}</span>
                                  </span> : ''}
                                </span>
                                <span className="body">{exists.value}</span>
                              </div>
                            );
                          }
                        } else {
                          return (
                            <div className={`feature ${idx % 2 === 0 ? "" : "alternate"}`}>
                              <span className="body featureTitle">{feature.title}
                                {feature.tooltip ? <span class="tooltip"><BsQuestionCircleFill />
                                  <span class="tooltiptext">{feature.tooltip}</span>
                                </span> : ''}
                              </span>
                              <span className="times">
                                <FaCircleXmark />
                              </span>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                ))}

              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default PartnerLevels;
