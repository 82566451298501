import { useWindowSize } from "@react-hook/window-size/throttled";
import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { BsFillCaretDownFill } from "react-icons/bs";
import "./careers.scss";
import StrokePattern from "../../components/stroke-pattern/stroke-pattern";
import Marquee from "react-fast-marquee";

const Careers = () => {
  const [width, height] = useWindowSize();
  const [playerSize, setPlayerSize] = useState("768px");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (width > 1420) {
      setPlayerSize("512px");
    } else if (width <= 1420 && width > 968) {
      setPlayerSize("450px");
    } else if (width <= 968 && width > 720) {
      setPlayerSize("400px");
    } else {
      setPlayerSize("320px");
    }

  }, [width]);

  return (
    <>

      <div className="careers">
        <h1 className="bannerHeading tertiary-dark-text">Careers</h1>

        <div className="leftSection">
          {/* <StrokePattern styles={{position: 'absolute', top: '0', left: '-100px'}}/> */}
          <img src="/images/Careers.webp" alt="careers" className="careerImage" />

          {/* <lottie-player src="/lottie-files/career-2.json" background="transparent" speed="1" style={{ width: playerSize, height: playerSize }} autoplay loop></lottie-player> */}
          {/* <h2>Join in on the fun today.</h2> */}
        </div>
        <div className="rightSection">
          <p className="body">
            Kzen8 is always looking for confident individuals who are passionate about building a career around supporting thriving businesses.
            <br></br>
            <br></br>
            We are honest, open, frank, and are open to working with people of any level of commercial experience, age, gender or ethnicity.
            <br></br>
            <br></br>
            If you want to start an extraordinary career full of adventure, growth and contribution then we would love to hear from you.
            <br></br>

            <h2 class="headerSmall tertiary-text">More details</h2>
            <Accordion className="accordion">
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<BsFillCaretDownFill />}
                sx={{ backgroundColor: '#e3e6ff' }}
              >
                <h3 className='subtitle2' style={{ margin: 0 }}>The first 6 months</h3>
              </AccordionSummary>
              <AccordionDetails>
                Over the initial 6 months you will be paid 20 hours per week whilst undergoing hands on training at our offices in the Gold Coast, QLD, Australia. This will include working closely with other team members on real projects.
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<BsFillCaretDownFill />}
                sx={{ backgroundColor: '#e3e6ff' }}
              >
                <h3 className='subtitle2' style={{ margin: 0 }}>What will I learn during training?</h3>

              </AccordionSummary>
              <AccordionDetails>
                You will receive in depth training and hands-on experience in:

                <ul>

                  <li> Business analysis and solution design<br></br></li>

                  <li>  Configuring our platform to meet requirements<br></br></li>

                  <li>  Project management<br></br></li>
                  <li> Sales and marketing<br></br></li>

                  <li>  Training and supporting users<br></br></li>


                </ul>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion">
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<BsFillCaretDownFill />}
                sx={{ backgroundColor: '#e3e6ff' }}
              >
                <h3 className='subtitle2' style={{ margin: 0 }}>What to expect once I complete the training?</h3>

              </AccordionSummary>
              <AccordionDetails>
                Once training is completed then a couple of options become available:
                <ul>
                  <li> Continuing to work with Kzen8, helping us support the ever growing global community of Kzen8ors<br></br></li>
                  <li> Working for yourself as a Kzen8or, delivering solutions to the world with our full support<br></br></li>
                </ul>

                Whatever path is right for you, the knowledge you will have learned whilst working at Kzen8 will be invaluable business skills that will stay with you for the rest of your career.

              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion">
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<BsFillCaretDownFill />}
                sx={{ backgroundColor: '#e3e6ff' }}
              >
                <h3 className='subtitle2' style={{ margin: 0 }}>What do I need to be successful?</h3>

              </AccordionSummary>
              <AccordionDetails>
                To be successful you will need to demonstrate that you are resourceful, bright, passionate, a self-learner, have strong values around integrity and have the drive to push yourself to be the best of the best.
                <br></br><br></br>
                We recommend you sign up as a Kzen8or on our platform and complete the free online training.
                <br></br><br></br>
                You will need to provide us with a 5-10 minute video that not only tells us about you, but also showcases something that you have created using the Kzen8 platform.

              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion">
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<BsFillCaretDownFill />}
                sx={{ backgroundColor: '#e3e6ff' }}
              >
                <h3 className='subtitle2' style={{ margin: 0 }}>Our hiring process</h3>

              </AccordionSummary>
              <AccordionDetails>
                We will conduct a short 15 minute online interview with you if you manage to stand out from the crowd and follow up with a 1 hour more face to face interview if we feel you are a good match.
                <br></br><br></br>
                Successful candidates will be offered a position within 1 week.

              </AccordionDetails>
            </Accordion>

            <br></br>
            To apply, simply email us at <a href="mailto:careers@kzen8.com" className="primary-text">careers@kzen8.com</a>

          </p>
        </div>

      </div>



      <div className='valuesSection full-width-element'>
        <h1 className={`bigHeading`}> Our Values</h1>
        <div className="testimonialContainer">
          <Marquee gradientColor={[247, 247, 247]} speed={20} gradientWidth={width > 968 ? 200 : width > 720 ? 100 : 50}>
            {[1, 2, 3, 4, 5, 6].map((num, idx) => (
              <span key={idx} className="testimonial subtitle2">
                <img src={`/images/values (${num}).png`} />
              </span>
            ))}
          </Marquee>
        </div>
      </div>
    </>
  );
};

export default Careers;
