import { useWindowSize } from '@react-hook/window-size/throttled';
import React, { useEffect, useRef, useState } from 'react';

import './business-assessment.scss';

const BusinessAssessment = () => {

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://static.scoreapp.com/js/integration/v1/embedding.js?v=gk3vK3";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    return (
        <div className="businessAssessment full-width-element">
            <div
                data-sa-url="https://46df25fc-86bc-4145-aca7-550cb03d6952.scoreapp.com/?sa_hide_header=1&sa_hide_footer=1&sa_target=_top"
                data-sa-view="inline"
                data-sa-auto-height="1"></div>

        </div>
    );
}

export default BusinessAssessment;
