import React from 'react'
import './stroke-pattern.scss';

const StrokePattern = ({styles}) => {
  return (
    <div className='StrokePattern' >
        <img src='/images/Stroke Pattern.svg' alt='filled pattern' style={styles}></img>
    </div>
  )
}

export default StrokePattern