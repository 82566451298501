import { useWindowSize } from "@react-hook/window-size/throttled";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaLinkedinIn, FaDiscord, FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import "./footer.scss";
import { Button } from "@mui/material";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import GetStartedToday from "../get-started-today/get-started-today";
import { RiUserAddFill } from "react-icons/ri";
import UserPreferenceContext from "../../contexts/user-preference-context";
import { visibilityCheck } from "../../utils/visibility-check";
import { IoChatbox } from "react-icons/io5";
import { MdEditSquare } from "react-icons/md";

export default function Footer() {
  const [playerSize, setPlayerSize] = useState("768px");
  const [width, height] = useWindowSize();
  const userPreference = useContext(UserPreferenceContext);

  useEffect(() => {
    if (width > 720) {
      setPlayerSize("220px");
    } else {
      setPlayerSize("220px");
    }

  }, [width]);

  return (
    <>
      {/* <GetStartedToday /> */}
      <div className="footer">
        <h3 className="header app-background-text-colour" style={{ margin: "20px 0 120px 0", display: visibilityCheck(userPreference, ['O']) }}>
          Start your Kzen8 journey
        </h3>
        <h3 className="header" style={{ margin: "20px 0 120px 0", display: visibilityCheck(userPreference, ['C']) }}>
          Start earning with Kzen8
        </h3>
        <h3 className="header" style={{ margin: "20px 0 120px 0", display: visibilityCheck(userPreference, ['T']) }}>
          Start building with Kzen8
        </h3>
        <div className="lottieContainer">
          <lottie-player src="/lottie-files/consultant.json" background="transparent" speed="1" style={{ width: playerSize, height: playerSize }} autoplay loop></lottie-player>
        </div>

        <div className='buttonContainer'>
          <Link to="https://community.kzen8.com/" target='_blank'>
            <Button className="btn btn-tertiary light callToAction subtitle2" variant="contained" endIcon={<MdEditSquare />}>
              Join Our Community
            </Button>
          </Link>
        </div>

        <div className="socialLinkBox">
          <a href="https://www.youtube.com/@kzen8842" target="_blank" rel="noreferrer">
            <FaYoutube />
          </a>
          {/* <a href="https://www.youtube.com/@kzen8842" target="_blank" rel="noreferrer">
            <FaDiscord />
          </a> */}
          <a href="https://www.linkedin.com/company/kzen8/" target="_blank" rel="noreferrer">
            <FaLinkedinIn />
          </a>
          {/* <a href="https://instagram.com/kzen8software?igshid=NmZiMzY2Mjc=" target="_blank" rel="noreferrer">
    <AiFillInstagram />
  </a> */}
        </div>

        <span className="subinfo">©{new Date().getFullYear()} Kzen8 Pty Ltd / All rights reserved</span>
      </div>
    </>
  );
}
