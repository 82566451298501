import React from "react";
import { BsFillLightningChargeFill, BsShieldFillCheck } from "react-icons/bs";
import { FaChartLine, FaMobile } from "react-icons/fa";
import LazyLoad from "react-lazyload";
import "./features.scss";

const Features = () => {
  return (
    <div className="Features full-width-element">
      <h1 className="bigHeading">Benefits</h1>
      <LazyLoad>

        <div className="featuresContainer">
          <div className="feature cardShadow animated slideUpAnimation">
            <BsFillLightningChargeFill className="app-background-text-colour" />
            <h2 className="headerSmall">Fast development</h2>
            <span className="body">
              Building complete solutions with the Kzen8 platform can easily be done hours or days where traditional software development would take weeks or months.
            </span>
          </div>
          <div className="feature  cardShadow animated slideUpAnimation  delay-1-quarter">
            <BsShieldFillCheck className="app-background-text-colour" />
            <h2 className="headerSmall">Secure</h2>
            <span className="body">
              Security of your data is our number one priority and you can confidently create solutions using the Kzen8 platform knowing that your data is secure
            </span>
          </div>
          <div className="feature  cardShadow animated slideUpAnimation  delay-1-half">
            <FaChartLine className="app-background-text-colour" />
            <h2 className="headerSmall">Scalable</h2>
            <span className="body">
              Hosted on AWS servers in a location of your choice, the Kzen8 platform has virtually unlimited scalability potential, ensuring solutions remain responsive at all times
            </span>
          </div>

          <div className="feature  cardShadow animated slideUpAnimation  delay-1">
            <FaMobile className="app-background-text-colour" />
            <h2 className="headerSmall">Mobile Friendly</h2>
            <span className="body">
              A themeable application player usable on mobile, tablet and desktop devices with full personalisation and branding options
            </span>
          </div>
        </div>
      </LazyLoad>

    </div>
  );
};

export default Features;
