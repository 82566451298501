import React from 'react';
import { Button } from "@mui/material";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { RiUserAddFill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import FilledPattern from '../../components/filled-pattern/filled-pattern';
import StrokePattern from '../../components/stroke-pattern/stroke-pattern';
import { MdEditSquare } from "react-icons/md";
import { IoChatbox } from "react-icons/io5";

const HomeOrganisation = () => {
    return (
        <div>

            <div className="middleSection">
                <div className="leftSection initially-hidden animated slideUpAnimation">
                    <StrokePattern styles={{ position: 'absolute', width: '100px', left: '-100px' }} />

                    <h1 className="bannerHeading primary-text-colour">
                        <span className="tertiary-dark-text">Business Software  <span className="primary-text">Made <span className="tertiary-text">Easy</span></span></span>
                    </h1>

                    <p className="body">Kzen8 helps organisations rapidly implement bespoke end-to-end back-office systems that are affordable and easy to modify.
                    </p>

                    <p className="body">Join our community to see how we're helping businesses of all sizes thrive and scale.

                    </p>
                    <div className="buttonContainer">
                        <Link to="https://community.kzen8.com/" target='_blank'>
                            <Button className="btn btn-tertiary callToAction subtitle2" variant="contained" endIcon={<MdEditSquare />}>
                                Join Our Community
                            </Button>
                        </Link>        
                        <Link to="https://hub.kzen8.com/signup" target='_blank'>
                            <Button className="btn btn-primary callToAction subtitle2" variant="contained" endIcon={<BsFillArrowRightCircleFill />}>
                                Start Building Today
                            </Button>
                        </Link>                       
                    </div>                 
                </div>
                <div className="rightSection initially-hidden animated slideLeftAnimation">
                    <video src="/videos/Kzen8 Global Partner Network 2024.mp4" autoPlay="true" playsInline="true" muted="false" controls alt="Kzen8 - Partner Expert">

                        <track default kind="captions" srclang="en" src="/subtitles/captions from Global Partner Network video.vtt" />


                    </video>
                    {/* <FilledPattern styles={{ position: 'absolute', top: '-100px', right: '-100px', zIndex: -1, width: '15vw', maxWidth: 'unset' }} /> */}
                    <StrokePattern styles={{ position: 'absolute', width: '200px', left: '-100px' }} />
                    <StrokePattern styles={{ position: 'absolute', width: '125px', top: 0, right: '-100px' }} />

                </div>
            </div>

        </div>
    );
}

export default HomeOrganisation;
