import React, { Component, useContext, useEffect, useState } from "react";
import "./home.scss";
import LazyLoad from "react-lazyload";
import UserPreferenceContext from "../../contexts/user-preference-context";
import HomeOrganisation from "./home-organisation";
import { visibilityCheck } from "../../utils/visibility-check";
import HomeCommercial from "./home-commercial";
import HomeTechnical from "./home-technical";

const Home = () => {

  const userPreference = useContext(UserPreferenceContext);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LazyLoad>
      <div className="home full-width-element secondary-text-colour">
        {/* <div className="clippedBackground animated" style={{ backgroundImage: `url('/images/hero-background-5.svg')` }}></div> */}
        <div style={{ display: visibilityCheck(userPreference, ['O']) }}>
          <HomeOrganisation />
        </div>
        <div style={{ display: visibilityCheck(userPreference, ['C']) }}>
          <HomeCommercial />
        </div>
        <div style={{ display: visibilityCheck(userPreference, ['T']) }}>
          <HomeTechnical />
        </div>
      </div>
    </LazyLoad>
  );
};

export default Home;
