import React, { useEffect } from "react";
import { FaUsers, FaCalendarAlt, FaTasks, FaWhmcs, FaRibbon, FaChartLine, FaStopwatch, FaArrowRight } from "react-icons/fa";

import { MdOutlineWatchLater } from "react-icons/md";

import "./solutions.scss";
import { useWindowSize } from "@react-hook/window-size/throttled";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";
import StrokePattern from "../../components/stroke-pattern/stroke-pattern";
import CountUp from "react-countup";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import CustomerRelationships from "./customer-relationships/customer-relationships";
import Solution from "./solution/solution";
import { solutions } from "./solutions-array";


const Solutions = () => {
  const [width, height] = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Solutions full-width-element">
      <div className="topDescription app-background-text-colour">
        <img src="/images/Dotted-random-pattern.svg" className="dotted-random-pattern" />
        <section className="left">
          <h1 className="bigHeading  app-background-text-colour">Build Solutions With Kzen8</h1>
          <h2 className="body app-background-text-colour">Anyone can work with businesses to rapidly develop the tools and systems they need using the Kzen8 platform.</h2>

          <div className="statsContainer">
            <div className="stats">
              <h1 className="header app-background-text-colour">
                <CountUp end={10} duration={0.75} />x
              </h1>
              <span className={width > 500 ? "subtitle2" : "subinfo"}>Faster development</span>
            </div>
            <div className="stats">
              <h1 className="header app-background-text-colour">
                <CountUp end={4} duration={0.25} />x
              </h1>
              <span className={width > 500 ? "subtitle2" : "subinfo"}>Resources saved</span>
            </div>
            <div className="stats">
              <h1 className="header app-background-text-colour">
                <CountUp end={3} duration={0.15} />x
              </h1>
              <span className={width > 500 ? "subtitle2" : "subinfo"}>Less downtime</span>
            </div>
          </div>
          <Link to="https://hub.kzen8.com/signup" target='_blank'>
            <Button className="btn subtitle2 app-background-text-colour" endIcon={<BsFillArrowRightCircleFill />}>
              Start Building Today
            </Button>

          </Link>
        </section>
        <section className="right"></section>
        <img src="/images/solutions-hero-image.webp" alt="solutions hero page" className="heroImage" />
      </div>

      <div className="comingSoonSection">
        <h2 className="bigHeading comingSoonHeading"></h2>
        <h2 className="headerSmall" style={{ display: 'flex', alignItems: 'center' }}>
          Here are some real life solutions that have been built with <br/>The Kzen8 Platform
          <img src="/images/arrow-down.png" style={{ width: '50px' }} />
        </h2>
        <div className="comingSoonexampleSection body">
          {solutions.map((example) => (
            <div key={example.title} className="usageExample initially-hidden animated slideUpAnimation cardShadow">
              <div className="headerImage" style={{ backgroundImage: `url(${example.imageLink})` }}></div>
              <div className="descriptionSection">
                <span className="headerSmall tertiary-dark-text">{example.title}</span>
                <div className="description">
                  <p>{example.firstParagraph}</p>
                  <p>{example.secondParagraph}</p>

                </div>

                {example.navigationLink ? (
                  <Link className="navigationLink tertiary-dark-text accent" to={example.navigationLink}>
                    <span>
                      Learn more <FaArrowRight />
                    </span>
                  </Link>

                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Solutions;
