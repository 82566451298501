import React from 'react';
import { Button } from "@mui/material";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { RiUserAddFill } from "react-icons/ri";
import { Link } from 'react-router-dom';

const HomeTechnical = () => {
    return (
        <div>

            <div className="middleSection">
                <div className="leftSection initially-hidden animated slideUpAnimation">
                    <h1 className="bannerHeading primary-text-colour">
                    Build <span className="primary-text">Software</span><br/> With Ease
                    </h1>

                    <p className="body"> Kzen8 is revolutionising the business software industry with our game changing no code platform.
                    </p>
                    {/* <p className="body">
            Have you engaged coaches and consultants to help scale your business but found the results to be disappointing?
            </p> */}
                    <p className="body">Find out how the Kzen8 platform empowers anyone to build world-class business software solutions, even if they are not a software engineer.
                    </p>
                    <div className="buttonContainer">
                        <a href="https://hub.kzen8.com/signup" target="_blank" rel="noopener noreferrer">
                            <Button className="btn btn-primary callToAction subtitle2" variant="contained" endIcon={<RiUserAddFill />}>
                                Sign up for free
                            </Button>
                        </a>
                    </div>
                </div>
                <div className="rightSection initially-hidden animated slideLeftAnimation">
                    {/* <lottie-player
              src="/lottie-files/home-animation.json"
              // src="https://assets7.lottiefiles.com/packages/lf20_tcwozhzv/MarketingCampaignsViralMethods.json"
              background="transparent"
              speed="1"
              style={{ width: playerSize, height: playerSize }}
              autoplay
              loop
            ></lottie-player> */}
                </div>
                <div className="heroImageContainer">
                    <img src="/images/hero-image-technical.webp" alt="hero-section-image"></img>
                    <img src="/images/Popup1-technical.svg" alt="popup1" key="popup1" className="popup1  scaleIn" />
                    <img src="/images/Popup2-technical.svg" alt="popup2" key="popup2" className="popup2  scaleIn" />
                    <img src="/images/Popup3-technical.svg" alt="popup3" key="popup3" className="popup3  scaleIn" />


                </div>
            </div>
        </div>
    );
}

export default HomeTechnical;
